
.main_div{
    position: relative;
    overflow: hidden;
    box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 5%), 0px 0px 2px 2px rgb(0 0 0 / 10%);
    padding: 10px 5px;
    margin-top: 2%;
    background: #fefefe;
    border-radius: 2px;
}
  .main_div:hover::before{
    content: '';
    position: absolute;
    width:100%;
    height: 70px;
    background: linear-gradient(#ffb700cc,#4871e3);
    animation: animate 3s linear infinite;
}
  
.main_div::after{
    content: '';
    position: absolute;
    inset: 4px;
    background: #fff;
}

@keyframes animate {
    0%
    {
        transform: rotate(10deg);
    }
    100%
    {
        transform: rotate(350deg);
    }
}
  
.textareas{
    position: relative;
    z-index: 10;
}
  

.notes_lab {
    color: forestgreen;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 0px;
}


.row_div {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 10px 5px;
    margin-top: 1%;
    background: #fefefe;
    border-radius: 2px;

}

.row_div p {
    font-size: 15px;
}

#sendquery {
    position: fixed;
    bottom: -4px;
    right: 0px;
}

.sub_header{
    color: forestgreen;
    font-weight: bold;
    font-size: 17px;
    margin-left: 14px;
    margin-bottom: 0px;
}


ul ul {
    
    list-style-type: disc !important;
}