
.mainPoster {
    display: flex;
    background-image: url('https://www.etaxigo.com/blog/wp-content/uploads/2020/02/Most-Beautiful-Tourist-Places-In-Uttarakhand-1.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: 100% 100%;
    width: 100%;
    min-height: 360px;

    justify-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    
}

.SearchBar {
    border-start-start-radius: 10px;
    border-end-start-radius: 10px;
    border: none;
    cursor: pointer;
    width: 30%;
    height: 10%;
    height: 38px;
    margin-top: 100px;
    margin-left: 34%;
    
}

.SearchBar-btn {
    border: none;
    height: 10%;
    margin-top: 100px;
    background-color: black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.SearchBar:focus {
    outline: none !important;
}


.slick-next, .slick-prev {
    background: #7b7f83!important;
    color: #212529!important;
    border-radius: 12px !important;
}