.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}


.hr{
  width: 95%;
  margin: auto;
}

.iframebackgraou{
  background-image: url('https://tse4.mm.bing.net/th?id=OIP.Vtv722jeIaDbzql9vPNtQAHaDt&pid=Api&P=0');
  background-repeat: no-repeat;
  background-attachment: center; 
  background-size: 85% 80%;
  width: 100%;
  height: 500px;
}


.iframebtn {
  background-color: #f58120;
  margin-top: 50px !important;
  -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  height: 48px;
  width: 213px;
  font-family: "Alegreya Sans", Arial;
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  position: relative;
}

.firstUperCase{
  text-transform: capitalize;
}

.form-label {
  margin-bottom: 0.2rem !important;
}


.Review-Btn {
  border: none;
  background-color: #30b3ff;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  font-family: sans-serif;
  width: 14%;
  font-weight: 600;
  
}

.contactUS-main-div {
  border-radius: 10px;
  width: 40%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (max-width:767px) { 
  .contactUS-main-div {
    width: 80%;
  } 
}

@media (max-width:767px) { 
  .Review-Btn {
    width: 100px;
    height: 35px;
  } 
}




