.UserSidebarDashboard {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
  font-size: 18px;
  text-align: center;
}
.sidebarOptions {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  font-family: DM Sans;
}
.sidebarOptions:hover {
  color: rgb(30, 192, 250);
  font-size: 15px;
  font-weight: 500;
  font-family: DM Sans;
  cursor: pointer;
}
.Sidebarlogoutbtn {
  box-sizing: border-box;
  margin: 0;
  width: 60%;
  height: 10%;
  display: inline-block;
  text-decoration: none;
  font-size: 13px;
  color: #fff;
  background-color: #2b97a4;
  border: 0;
  font-family: DM Sans;
  font-weight: 0;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
}

.Sidebarlogoutbtn:hover {
  background-color: #051646;
}
.Sidebarlogoutbtn:active {
  background-color: #020718;
}
.Sidebarlogoutbtn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 678px) {
  #sidebarUser {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .sideTabsClass {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .tabsClass {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.nav-link.active:focus {
  font-family: Dm Sans;
  font-weight: bold;
  background-color: #2b97a4 !important;
  color: white;
}

.notDropdown .accordion-button::after {
  display: none;
}

.notDropdown .accordion-button:not(.collapsed)::after {
  display: none;
}
